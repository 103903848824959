import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { concat, forkJoin } from 'rxjs';

export interface IAppInfo {
  buildInfo: {
    buildNumber: number;
    buildTime: string;
    buildInstance: string;
    buildBranch: string;
    buildHash: string;
    buildVersion: string;
  }
}
export interface IAppConfig {
  environment: string;
  loginDialog?: string;
  api: {
    applUrl: string;
    baseUrl: string;
    authUrl: string;
  }
  transaction: boolean,
  inquiry: boolean
}

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  static appInfo: IAppInfo;
  static appConfig: IAppConfig;

  constructor(private http: HttpClient) { }

  loadConfig() {
    console.log("::loadConfig");
    forkJoin({ info: this.http.get('/assets/app.info.json'), config: this.http.get('/assets/app.config.json') })
      .subscribe((data) => {
        AppConfigService.appInfo = data.info as IAppInfo;
        AppConfigService.appConfig = data.config as IAppConfig;
        console.log("::loadConfig appConfig:", AppConfigService.appConfig);
        console.log("::loadConfig appInfo:", AppConfigService.appInfo);
        console.log(data);
      });
    return null;
  }

}
